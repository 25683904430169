import React from "react";
import playIcon from "../assets/Icons/play-icon.png";
export default function TutorialCard({ data, uniqueId }) {
  debugger;
  const { img, title, link } = data;
  return (
    <div>
      <img alt="" className="w-100" src={img} />
      <div className="d-flex mt-4 justify-content-between row">
        <div className="fs-6 text-uppercase align-self-center col-6">
          {" "}
          {title}{" "}
        </div>
        <a
          className="text-uppercase text-decoration-none text-mrep col-6 text-end"
          data-bs-toggle="modal"
          href={`#exampleModalToggle${uniqueId}`}
          role="button"
        >
          Watch intro <img src={playIcon} />
        </a>
      </div>

      {/* Model Here */}
      <div
        className="modal fade"
        id={`exampleModalToggle${uniqueId}`}
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div className="modal-dialog modal-lg  modal-dialog-centered">
          <div className="modal-content">
            <div className="">
              {/* <h5 className="modal-title" id="exampleModalToggleLabel">
                Modal 1
              </h5> */}
              <button
                type="button"
                className="btn-close modal-btn "
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body p-0">
              <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                <iframe
                  title={title}
                  src={link}
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  //   title="VID-20230130-WA0033.mp4"
                ></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
