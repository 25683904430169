import React from "react";
import mrepDarkLogo from "../../assets/Images/Logo-dark.png";
import fb from "../../assets/Icons/fb.png";
import linkedin from "../../assets/Icons/linkedin.png";
import twitter from "../../assets/Icons/twitter.png";
import insta from "../../assets/Icons/insta.png";
export default function Footer() {
  return (
    <div className="container mt-5">
      <div className="text-center">
        <img src={mrepDarkLogo} />
      </div>
      <div className="row py-3">
        <div className="col-md-4 col-12 text-md-start text-center">
          <a
            href="https://tharsol.com/privacyPolicy"
            className="text-decoration-none text-dark me-1"
          >
            Terms & Conditions
          </a>
          |
          <a
            href="https://tharsol.com/privacyPolicy"
            className="text-decoration-none text-dark ms-1"
          >
            Privacy Policy
          </a>
        </div>
        <div className="col-md-4 col-12 text-center ">
          Copyright © 2023 MRep. All rights reserved.
        </div>
        <div className="col-md-4  text-md-end text-center">
          <a className="mx-2">
            <img src={fb} />
          </a>
          <a className="mx-2">
            <img src={linkedin} />
          </a>
          <a className="mx-2">
            <img src={twitter} />
          </a>
          <a className="mx-2">
            <img src={insta} />
          </a>
        </div>
      </div>
    </div>
  );
}
