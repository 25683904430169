import React from "react";
import { Link } from "react-router-dom";
import checkIcon from "../assets/Icons/check-icon.png";
import { Button } from "./Button";
export default function PriceCard({ data }) {
  const { icon, title, price, features, color } = data;
  return (
    <div className="text-center shadow rounded-16 h-100">
      <div className="pt-4 px-5  min-h-330">
        <img src={icon} />
        <div className={`text-uppercase ${color}`}> {title} </div>
        <div className=" fs-3"> {price} </div>
        <p className="text-muted">Billed Annually</p>
        {features?.map((d) => {
          return (
            <p className="text-start">
              <img src={checkIcon} height="24px" /> {d}
            </p>
          );
        })}
      </div>
      <div className="p-5 bg-light rounded-b-16 sticky-bottom">
        <button className="btn bg-mrep text-white w-100">
          <Link style={{all:"unset"}} to="/ContactUs">Get Started</Link>
        </button>
      </div>
    </div>
  );
}
