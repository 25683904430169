import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import aspinLogo from "../assets/Images/aspin-logo-2.png";
import chiesiLogo from "../assets/Images/chiesi.png";
import pharmevoLogo from "../assets/Images/pharmevo.png";
import arrowBack from "../assets/Icons/arrow-back.png";
import arrowRight from "../assets/Icons/arrow-right.png";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function CaseStudyCarousel() {
  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className="text-center py-4">
        <button className="btn" onClick={() => previous()}>
          {" "}
          <img src={arrowBack} />{" "}
        </button>
        <button className="btn" onClick={() => next()}>
          {" "}
          <img src={arrowRight} />{" "}
        </button>
      </div>
    );
  };
  return (
    <div className="container my-5 ">
      <Carousel
        responsive={responsive}
        arrows={false}
        renderButtonGroupOutside={true}
        customButtonGroup={<ButtonGroup />}
      >
        <div>
          <div className="row">
            <div className=" col-md-2">
              <div className="text-center text-white ">
                <img
                  style={{borderRadius:"10px"}}
                  alt=""
                  src={chiesiLogo}
                  className="align-self-center"
                  width="89px"
                  height="89px"
                />
              </div>
            </div>
            <div className="col-md-10">
              <div className="fs-1 lh-1 text-white    align-self-center">
                Streamlining Sales Process for CHIESI Pharmaceuticals
              </div>
            </div>
          </div>
          <div className="row pb-5">
            <div className="col-lg-3 col-md-6 my-md-2 my-lg-0 my-3">
              <span className="text-uppercase bg-white p-2 px-3 rounded-0 badge">
                Problem
              </span>
              <div className="border rounded-29 m-2 p-3 pb-0 pt-5 h-100 bg-mrep">
                <div className="text-white pt-4 p-2 ">
                  CHIESI Pharmaceuticals was facing challenges with managing its
                  sales pipeline. The sales team was spending too much time on
                  adminsitrative tasks, resulting in a low sales productivity.
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 my-md-2 my-lg-0 my-3">
              <span className="text-uppercase bg-white p-2 px-3 rounded-0 badge">
                Solution
              </span>
              <div className="border rounded-29 m-2 p-3 pb-0 pt-5 h-100 bg-mrep">
                <div className="text-white p-2 pt-4">
                  CHIESI Pharmaceuticals implemented MRep CRM, a sales force
                  automation software designed specifically for the
                  pharmaceutical industry. The software's automated pipeline
                  management feature allowed the sales team to easily track
                  leads and opportunities, resulting in a 37% increase in sales
                  productivity.
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 my-md-2 my-lg-0 my-3">
              <span className="text-uppercase bg-white p-2 badge rounded-0 px-3">
                Results
              </span>
              <div className="border rounded-29 m-2 p-3 pb-0 pt-5 h-100 bg-mrep">
                <div className="text-white p-2 pt-4">
                  <div className="fs-2">37%</div>
                  increase in sales productivity Improved efficiency in managing
                  sales pipeline More time for sales representatives to focus on
                  selling.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="row">
            <div className=" col-md-2">
              <div className="text-center text-white ">
                <img
                  alt=""
                  src={aspinLogo}
                  className="align-self-center"
                  width="89px"
                  height="89px"
                />
              </div>
            </div>
            <div className="col-md-10">
              <div className="fs-1 lh-1 text-white    align-self-center">
                Optimizing Sales Efforts for ASPIN Pharmaceuticals
              </div>
            </div>
          </div>
          <div className="row pb-5">
            <div className="col-lg-3 col-md-6 my-md-2 my-lg-0 my-3">
              <span className="text-uppercase bg-white p-2 px-3 rounded-0 badge">
                Problem
              </span>
              <div className="border rounded-29 m-2 p-3 pb-0 pt-5 h-100 bg-mrep">
                <div className="text-white pt-4 p-2 ">
                  ASPIN Pharmaceuticals was facing challenges with managing its
                  sales territories. The sales team was not able to optimize
                  their sales efforts resulting in low sales productivity.
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 my-md-2 my-lg-0 my-3">
              <span className="text-uppercase bg-white p-2 px-3 rounded-0 badge">
                Solution
              </span>
              <div className="border rounded-29 m-2 p-3 pb-0 pt-5 h-100 bg-mrep">
                <div className="text-white p-2 pt-4">
                  ASPIN Pharmaceuticals implemented MRep CRM, a sales force
                  automation software designed specifically for the
                  pharmaceutical industry. The software's new Territory
                  Management/Alignment module allowed the sales team to manage
                  and align their sales territories resulting in a 15% increase
                  in sales productivity.
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 my-md-2 my-lg-0 my-3">
              <span className="text-uppercase bg-white p-2 badge rounded-0 px-3">
                Results
              </span>
              <div className="border rounded-29 m-2 p-3 pb-0 pt-5 h-100 bg-mrep">
                <div className="text-white p-2 pt-4">
                  <div className="fs-2">15%</div>
                  increase in sales productivity Improved efficiency in managing
                  sales territories More time for sales representatives to focus
                  on selling.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="row">
            <div className=" col-md-2">
              <div className="text-center text-white ">
                <img
                  alt=""       style={{borderRadius:"10px"}}
                  src={pharmevoLogo}
                  className="align-self-center"
                  width="89px"
                  height="89px"
                />
              </div>
            </div>
            <div className="col-md-10">
              <div className="fs-1 lh-1 text-white    align-self-center">
                Optimizing Sales Efforts for ASPIN Pharmaceuticals
              </div>
            </div>
          </div>
          <div className="row pb-5">
            <div className="col-lg-3 col-md-6 my-md-2 my-lg-0 my-3">
              <span className="text-uppercase bg-white p-2 px-3 rounded-0 badge">
                Problem
              </span>
              <div className="border rounded-29 m-2 p-3 pb-0 pt-5 h-100 bg-mrep">
                <div className="text-white pt-4 p-2 ">
                  Pharmevo, a pharmaceutical company, was facing challenges with
                  improving team performance. The company was using manaual
                  methods for tracking team memeber's performance and progress,
                  resulting in a lack of data insights and inefficiency.
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 my-md-2 my-lg-0 my-3">
              <span className="text-uppercase bg-white p-2 px-3 rounded-0 badge">
                Solution
              </span>
              <div className="border rounded-29 m-2 p-3 pb-0 pt-5 h-100 bg-mrep">
                <div className="text-white p-2 pt-4">
                  Pharmevo implemented MRep CRM, a sales force automation
                  software designed specifically for the pharmaceutical
                  industry. The software's team management feature allowed the
                  company to track each team member's performance and progress,
                  allowing the company to make data-driven decisions.
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 my-md-2 my-lg-0 my-3">
              <span className="text-uppercase bg-white p-2 badge rounded-0 px-3">
                Results
              </span>
              <div className="border rounded-29 m-2 p-3 pb-0 pt-5 h-100 bg-mrep">
                <div className="text-white p-2 pt-4">
                  After implimenting MRep CRM, Pharmevo was able to improve team
                  performance and drive revenue growth. The company was able to
                  make data-driven decisions, resulting in an increase in
                  overall sales.
                </div>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
}
