import React from "react";
import { Button } from "../Button";
import Nav from "./Nav";

export default function HeaderContact({ children }) {
  return (
    <div className="min-h-1200">
      <div className="header-faq-bg">
        <div className="container">
          <div className="row pt-5">
            <div className=" mt-5">
              <div className="banner-h mt-5 text-center">
                Contact <span className="bg-yellow"> Us </span>
              </div>
              <p className="fs-4 my-4 col-md-8 offset-md-2 text-center">
                Everything you need to know about the product and billing. Can’t
                find the answer you’re looking for?
              </p>
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}
