import React from "react";
import HeaderFAQ from "../components/layout/HeaderFAQ";
import { Link } from "react-router-dom";
import img from "../assets/Images/img-33.png";
import logoSm from "../assets/Images/logo-sm.png";
export default function FAQ() {
  return (
    <div className="container-fluid px-0">
      <HeaderFAQ />

      {/* FAQ Section */}

      <div className="container my-5 py-5">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button fw-bold text-dark collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#s1"
                    aria-expanded="false"
                    aria-controls="s1"
                  >
                    What is MRep?
                  </button>
                </h2>
                <div
                  id="s1"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body text-muted">
                    MRep is a sales management software designed to help
                    businesses streamline their sales processes, increase
                    productivity, and improve their bottom line.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                  <button
                    className="accordion-button fw-bold text-dark collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#s2"
                    aria-expanded="false"
                    aria-controls="s2"
                  >
                    How does MRep work?
                  </button>
                </h2>
                <div
                  id="s2"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body text-muted">
                    MRep provides a variety of tools and features such as
                    automated pipeline management, advanced reporting and
                    analytics, mobile access, field expense management,
                    coaching, and training to help sales teams better manage
                    their workflow and improve their performance.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingThree">
                  <button
                    className="accordion-button fw-bold text-dark collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#s3"
                    aria-expanded="false"
                    aria-controls="s3"
                  >
                    Who can use MRep?
                  </button>
                </h2>
                <div
                  id="s3"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body text-muted">
                    MRep is designed for businesses of all sizes and industries,
                    from small startups to large enterprise organizations.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingThree">
                  <button
                    className="accordion-button fw-bold text-dark collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#s4"
                    aria-expanded="false"
                    aria-controls="s4"
                  >
                    What are the benefits of using MRep?
                  </button>
                </h2>
                <div
                  id="s4"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body text-muted">
                    MRep can help businesses increase productivity, improve
                    sales performance, streamline their sales processes, and
                    ultimately increase revenue.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingThree">
                  <button
                    className="accordion-button fw-bold text-dark collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#s5"
                    aria-expanded="false"
                    aria-controls="s5"
                  >
                    Is MRep easy to use?
                  </button>
                </h2>
                <div
                  id="s5"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body text-muted">
                    Yes, MRep is user-friendly and easy to use, even for those
                    with little to no experience with sales management software.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingThree">
                  <button
                    className="accordion-button fw-bold text-dark collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#s6"
                    aria-expanded="false"
                    aria-controls="s6"
                  >
                    Does MRep integrate with other software?
                  </button>
                </h2>
                <div
                  id="s6"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body text-muted">
                    Yes, MRep can integrate with a variety of other software
                    such as CRM systems, marketing automation software, and
                    more.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingThree">
                  <button
                    className="accordion-button fw-bold text-dark collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#s8"
                    aria-expanded="false"
                    aria-controls="s8"
                  >
                    How much does MRep cost?
                  </button>
                </h2>
                <div
                  id="s8"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body text-muted">
                    Pricing for MRep is based on the number of users, starting
                    at £5 per user.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingThree">
                  <button
                    className="accordion-button fw-bold text-dark collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#s9"
                    aria-expanded="false"
                    aria-controls="s9"
                  >
                    Can I try MRep before I buy it?
                  </button>
                </h2>
                <div
                  id="s9"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body text-muted">
                    Yes, MRep offers a free trial for businesses to test the
                    software before committing to a purchase.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingThree">
                  <button
                    className="accordion-button fw-bold text-dark collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#s10"
                    aria-expanded="false"
                    aria-controls="s10"
                  >
                    How can I get more information about MRep?
                  </button>
                </h2>
                <div
                  id="s10"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body text-muted">
                    Visit our website or contact us for more information about
                    MRep and to schedule a demo.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* FAQ Section */}
      <div className="my-gradient">
        <div className="footer-bg-img pb-5 mb-15 ">
          <div className="container">
            <div className="bg-mrep rounded-29 ">
              <div className="row">
                <div className="col-md-6">
                  <div className="px-4 py-5">
                    <img src={img} className="img-fluid" height="410px" />
                  </div>
                </div>
                <div className="col-md-6 align-self-center ">
                  <div className="py-5 px-5 align-self-center">
                    <img src={logoSm} />
                    <p className="fs-1 my-3 mrep_header2 text-white">
                      Increased efficiency and productivity through{" "}
                      <span className="bg-yellow text-dark">
                        streamlined processes
                      </span>{" "}
                      and automated workflow
                    </p>
                    <Link className="" to="/ContactUs">
                      <button className="btn bg-light text-mrep">
                        Contact Us
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
