import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import logo1 from "../assets/Images/Clients/agp.png"; //
import logo2 from "../assets/Images/Clients/aspin.png";//
import logo3 from "../assets/Images/Clients/chiesi_logo.png";//
import logo4 from "../assets/Images/Clients/hiltonpharma.png";//
import logo5 from "../assets/Images/Clients/pharmevo.png";//
import logo6 from "../assets/Images/Clients/schazoozaka.png";//
import logo7 from "../assets/Images/Clients/servier_logo.png";//
import logo8 from "../assets/Images/Clients/sanofi.png";//
import logo9 from "../assets/Images/Clients/helix.png";//
// import logo10 from "../assets/Images/Clients/glitz_logo.png";
import logo11 from "../assets/Images/Clients/pharmatec_logo.png";//
import logo12 from "../assets/Images/Clients/herbion_logo.png";//
// import logo13 from "../assets/Images/Clients/globalpharma.png";
import logo14 from "../assets/Images/Clients/induspharma.png";//
// import logo15 from "../assets/Images/Clients/genix.png";
import logo16 from "../assets/Images/Clients/wimits_logo.png";//
import logo17 from "../assets/Images/Clients/ccl_logo.png";//
// import logo18 from "../assets/Images/Clients/brookes.png";
import logo19 from "../assets/Images/Clients/neutropharma.png";//
import logo20 from "../assets/Images/Clients/sjg.png";//
// import logo21 from "../assets/Images/Clients/pharmahealth.png";
import logo22 from "../assets/Images/Clients/galaxy.png";//
import logo23 from "../assets/Images/Clients/swiss.png";//
import logo24 from "../assets/Images/Clients/ferozsons.png";//
import logo25 from "../assets/Images/Clients/pharma5_logo.png";//
import logo26 from "../assets/Images/Clients/mcolson.png";//
import logo27 from "../assets/Images/Clients/medochemie.png";//
import logo28 from "../assets/Images/Clients/ferozsons_lms_logo.png";
import logo29 from "../assets/Images/Clients/excellabs_logo.png";//
import logo30 from "../assets/Images/Clients/allmed.png"; //
// import logo31 from "../assets/Images/Clients/amrantpharma.png";
import logo32 from "../assets/Images/Clients/medlife_logo.png";//
import logo33 from "../assets/Images/Clients/pharma5_logo_old.png";//
import logo34 from "../assets/Images/Clients/macter.png";//
import logo35 from "../assets/Images/Clients/amson_logo.png";//
import logo36 from "../assets/Images/Clients/sante.png";//
import logo37 from "../assets/Images/Clients/obs-pak.png";//
import logo38 from "../assets/Images/Clients/pacific.png";//
import logo39 from "../assets/Images/Clients/nabiqasim.png";//
import logo40 from "../assets/Images/Clients/phd-labs.png";//
import logo41 from "../assets/Images/Clients/obs-pharma.png";//
import logo42 from "../assets/Images/Clients/obs-agp.png";//
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 7,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 7,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function CarouselComp() {
  return (
    <div className="container my-5 py-5 px-md-0 px-5">
      <Carousel responsive={responsive}>
        <div>
          <img src={logo1} alt="" />
        </div>
        <div>
          <img src={logo2} alt="" />
        </div>
        <div>
          <img src={logo3} alt="" />
        </div>
        <div>
          <img src={logo4} alt="" />
        </div>
        <div>
          <img src={logo5} alt="" />
        </div>
        <div>
          <img src={logo6} alt="" />
        </div>
        <div>
          <img src={logo7} alt="" />
        </div>
        <div>
          <img src={logo8} alt="" />
        </div>
        <div>
          <img src={logo9} alt="" />
        </div>
        {/* <div>
          <img src={logo10} alt="" />
        </div> */}
        <div>
          <img src={logo11} alt="" />
        </div>
        <div>
          <img src={logo12} alt="" />
        </div>
        {/* <div>
          <img src={logo13} alt="" />
        </div> */}
        <div>
          <img src={logo14} alt="" />
        </div>
        {/* <div>
          <img src={logo15} alt="" />
        </div> */}
        <div>
          <img src={logo16} alt="" />
        </div>
        <div>
          <img src={logo17} alt="" />
        </div>
        {/* <div>
          <img src={logo18} alt="" />
        </div> */}
        <div>
          <img src={logo19} alt="" />
        </div>
        <div>
          <img src={logo20} alt="" />
        </div>
        {/* <div>
          <img src={logo21} alt="" />
        </div> */}
        <div>
          <img src={logo22} alt="" />
        </div>
        <div>
          <img src={logo23} alt="" />
        </div>
        <div>
          <img src={logo24} alt="" />
        </div>
        <div>
          <img src={logo25} alt="" />
        </div>
        <div>
          <img src={logo26} alt="" />
        </div>
        <div>
          <img src={logo27} alt="" />
        </div>
        <div>
          <img src={logo28} alt="" />
        </div>
        <div>
          <img src={logo29} alt="" />
        </div>
        <div>
          <img src={logo30} alt="" />
        </div>
        {/* <div>
          <img src={logo31} alt="" />
        </div> */}
        <div>
          <img src={logo32} alt="" />
        </div>
        <div>
          <img src={logo33} alt="" />
        </div>
        <div>
          <img src={logo34} alt="" />
        </div>
        <div>
          <img src={logo35} alt="" />
        </div>
        <div>
          <img src={logo36} alt="" />
        </div>
        <div>
          <img src={logo37} alt="" />
        </div>
        <div>
          <img src={logo38} alt="" />
        </div>
        <div>
          <img src={logo39} alt="" />
        </div>
        <div>
          <img src={logo40} alt="" />
        </div>
        <div>
          <img src={logo41} alt="" />
        </div>
        <div>
          <img src={logo42} alt="" />
        </div>
      </Carousel>
    </div>
  );
}
