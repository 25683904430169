import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import FooterBanner from "../components/FooterBanner";
import HeaderContact from "../components/layout/HeaderContact";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "../styles/Home.css";
export default function ContactUs() {
  const history = useHistory();
  const [phone, setPhone] = useState("");
  const [alert, setAlert] = useState(false);
  const [loader, setLoader] = useState(false);
  function handleClick(event) {
    
    event.preventDefault();

    const data = new FormData(event.target);
    // Access FormData fields with `data.get(fieldName)`
    // For example, converting to upper case
    data.set("FirstName", data.get("fName").toUpperCase());
    data.set("LastName", data.get("lName").toUpperCase());
    data.set("Email", data.get("email").toUpperCase());
    data.set("PhoneNo", data.get("phoneNo").toUpperCase());
    data.set("Message", data.get("message").toUpperCase());

    var jsonData = {
      FirstName: data.get("fName"),
      LastName: data.get("lName"),
      Email: data.get("email"),
      PhoneNo: data.get("phoneNo"),
      Message: data.get("message"),
    };
    // Send data to the backend via POST
    setLoader(true);
    fetch("https://dev.services.talluq.com.pk/api/Mrep/MrepEmail", {
      // Enter your IP address here

      method: "POST",
      // mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(jsonData),
    }).then(
      (res) => {
        setLoader(false);
        setAlert(true);
        window.setTimeout(() => {
          history.push("/");
        }, 3000);
      },
      (err) => {}
    );
  }
  return (
    <div className="container-fluid px-0">
      <HeaderContact>
        {/* FAQ Section */}

        <div className="container ">
          <div className="my-5 py-5">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6 bg-white rounded-5 shadow p-5">
                <form className="row g-3" onSubmit={handleClick}>
                  <div className="col-md-6">
                    <label for="fName" className="form-label">
                      First Name
                    </label>
                    <input
                      placeholder="First Name"
                      type="text"
                      className="form-control"
                      id="fName"
                      name="fName"
                      required={true}
                    />
                  </div>
                  <div className="col-md-6">
                    <label for="lName" className="form-label">
                      Last Name
                    </label>
                    <input
                      placeholder="Last Name"
                      type="text"
                      className="form-control"
                      id="lName"
                      name="lName"
                      required={true}
                    />
                  </div>
                  <div className="col-12">
                    <label for="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      placeholder="email@gmail.com"
                      className="form-control"
                      id="email"
                      required={true}
                      name="email"
                    />
                  </div>
                  <div className="col-12">
                    <label for="phone" className="form-label">
                      Phone No
                    </label>
                    <div className="input-group mb-3">
                      <PhoneInput
                        inputProps={{
                          id: "phoneNo",
                          name: "phoneNo",
                          required: true,
                        }}
                        inputclassName="mrep_phone"
                        country={"pk"}
                        enableSearch={true}
                        value={phone}
                        onChange={(phone) => setPhone(phone)}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <label for="message" className="form-label">
                      Message
                    </label>
                    <textarea
                      rows="5"
                      className="form-control rounded-3"
                      id="message"
                      required={true}
                      name="message"
                      placeholder="Leave us a Message"
                    />
                  </div>
                  <div className="col-12">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="gridCheck"
                        required={true}
                      />
                      <label className="form-check-label" for="gridCheck">
                        You agree to our friendly&nbsp;
                        <a
                          href="https://tharsol.com/privacyPolicy"
                          className="fw-bold text-dark"
                        >
                          privacy policy.
                        </a>
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn bg-mrep w-100 text-light"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
                {loader && (
                  <div class="row d-flex justify-content-center">
                    <div
                      class="spinner-grow text-dark"
                      style={{ width: "3rem", height: "3rem" }}
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
                {alert && (
                  <div className="row position-relative">
                    <div className="container p-5 position-absolute top-50 start-50 translate-middle">
                      <div className="alert alert-success" role="alert">
                        <h4 className="alert-heading">
                          Thank you for contacting us regarding MREP
                        </h4>
                        <p>
                          Your message has been received and one of our
                          representatives will get back to you shortly. In the
                          meantime, if you require immediate assistance, please
                          feel free to call us at +92 322 2816023.
                        </p>

                        <p className="mb-0">
                          We appreciate your business and are here to help
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-3"></div>
            </div>
            <div className="row">
              <div className="col-md-4 ">
                <div className="text-icon-box">
                  <div className="mrep_box_icon">
                    <i className="fa-solid fa-phone"></i>
                  </div>
                  <p className="mb-0">+92 322 8651010</p>
                  <p>+92 322 2816023</p>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="text-icon-box relative">
                  <div className="mrep_box_icon">
                    <i className="fa-solid fa-location-dot"></i>
                  </div>
                  <p>Office 217, 2nd floor, Mall of Faisalabad, Faisalabad.</p>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="text-icon-box">
                  <div className="mrep_box_icon">
                    <i className="fa-solid fa-envelope"></i>
                  </div>
                  <p className="mb-0">info@mrep.io</p>
                  <p>info@tharsol.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HeaderContact>
      {/* FAQ Section */}
    </div>
  );
}
