import logo from "./logo.svg";
import "./App.css";
import Home from "./pages/Home";
import Footer from "./components/layout/Footer";
import Nav from "./components/layout/Nav";
import {
  // BrowserRouter,
  // createBrowserRouter,
  // Route,
  // RouterProvider,
  // Routes,
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  Link,
} from "react-router-dom";
import FAQ from "./pages/FAQ";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";

function App() {
  const history = useHistory();
  // const router = createBrowserRouter([
  //   {
  //     path: "/",
  //     element: <Home />,
  //   },
  //   {
  //     path: "/FAQs",
  //     element: <FAQ />,
  //   },
  //   {
  //     path: "/ContactUs",
  //     element: <ContactUs />,
  //   },
  //   {
  //     path: "/AboutUs",
  //     element: <AboutUs />,
  //   },
  // ]);
  return (
    <div className="my-container">
      <Router history={history}>
        <Nav />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/ContactUs" component={ContactUs} />
          <Route exact path="/AboutUs" component={AboutUs} />
          <Route exact path="/FAQs" component={FAQ} />
          {/* <RouterProvider router={router} /> */}
          {/* <Home /> */}
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
