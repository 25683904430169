import React from "react";
import HeaderAbout from "../components/layout/HeaderAbout";
import SectionHeader from "../components/SectionHeader";
import logo from "../assets/Images/tharsol mrep.png";
import aboutImg from "../assets/Images/aboutImg.png";
import icon11 from "../assets/Icons/icon11.png";
import icon12 from "../assets/Icons/icon12.png";
import icon13 from "../assets/Icons/icon13.png";
import icon14 from "../assets/Icons/icon14.png";
import FooterBanner from "../components/FooterBanner";
export default function AboutUs() {
  const Card = ({ data }) => {
    const { icon, title, text } = data;
    return (
      <div className="text-center">
        <img src={icon} />
        <h4>{title}</h4>
        <p className="text-black-50">{text}</p>
      </div>
    );
  };
  return (
    <div className="container-fluid px-0">
      <HeaderAbout />

      <SectionHeader
        title="about mrep"
        heading="We are Dedicated to Providing  <span class='bg-yellow' > Cutting-edge</span> Software Solutions
        "
        content="At Tharsol, we are dedicated to providing cutting-edge software solutions that streamline business operations and drive growth. Our flagship product, MRep, is a prime example of this commitment. As a cloud-based software designed specifically for field sales teams, MRep offers a comprehensive suite of tools to manage leads, sales, expenses, coaching, and more."
      />
      <div className="text-center">
        <img src={logo} className="img-fluid" />
      </div>
      <div className="text-center my-5 pt-5">
        <img src={aboutImg} className="img-fluid" width="512px" />
      </div>

      <div className="container">
        <div className="row">
          {[
            {
              icon: icon11,
              title: "Mission",
              text: " Our mission is to provide innovative software solutions that help businesses of all sizes to streamline operations and drive growth. We are committed to providing our customers with the tools they need to succeed in today's fast-paced business environment.",
            },
            {
              icon: icon12,
              title: "Experience",
              text: " Tharsol has extensive experience in the software development industry, with a proven track record of delivering high-quality products and exceptional customer service. Our team has a wealth of knowledge and expertise in a wide range of industries, and we are always looking for ways to improve and evolve our products to meet the changing needs of our clients.",
            },
            {
              icon: icon13,
              title: "Commitment to Customers",
              text: " At Tharsol, we are dedicated to providing our customers with the best possible experience. We are committed to delivering high-quality products and exceptional customer service, and we work closely with our clients to understand their unique needs and requirements.",
            },
          ].map((d) => {
            return (
              <div className="col-md-4 col-12">
                <Card data={d} />
              </div>
            );
          })}
        </div>
      </div>

      {/* banner */}
      <div className="container py-5 ">
        <div className="banner-bg rounded-29">
          <div className="row text-white align-self-center h-100 py-5">
            <div className="col-md-5 col-12 mx-auto align-self-center">
              <div className="text-center ">
                <img src={icon14} height="54px" className="text-center mb-2" />
                <div className="fs-4  lh-base ">
                  To learn more about Tharsol and our other products, please
                  visit our website at
                  <a
                    href="https://www.tharsol.com"
                    className="bg-yellow text-dark"
                  >
                    {" "}
                    www.tharsol.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterBanner />
    </div>
  );
}
