import React from "react";

export default function SectionHeader({ title, heading, content }) {
  function createMarkup(data) {
    return { __html: data };
  }
  return (
    <div className="container pt-5 pb-5">
      <div className="row">
        <div className="col-lg-6 col-md-8 col-12 offset-lg-3 offset-md-2 ">
          <div className="text-center">
            <p className="text-uppercase text-muted">{title}</p>

            <div className="section-h" dangerouslySetInnerHTML={createMarkup(heading)}>
            </div>
            <p className="fs-6 text-muted my-3">{content}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
