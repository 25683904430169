import React from "react";
import Carousel from "../components/Carousel";
import Header from "../components/layout/Header";
import SectionHeader from "../components/SectionHeader";
import card1 from "../assets/Images/TutorialCard/card1.png";
import card2 from "../assets/Images/TutorialCard/card2.png";
import card3 from "../assets/Images/TutorialCard/card3.png";
import mobileImage from "../assets/Images/mobile-image.png";
import mobile1 from "../assets/Images/mobile1.png";
import mobile2 from "../assets/Images/mobile2.png";
import mobile3 from "../assets/Images/mobile3.png";
import mobile4 from "../assets/Images/mobile4.png";
import mobile5 from "../assets/Images/mobile5.png";
import mobile6 from "../assets/Images/mobile6.png";
import mobile7 from "../assets/Images/mobile7.png";
import mobile8 from "../assets/Images/mobile8.png";

import TutorialCard from "../components/TutorialCard";
import featuredIcon from "../assets/Icons/featured-icon.png";
import growIcon from "../assets/Icons/grow-icon.png";
import icon1 from "../assets/Icons/icon1.png";
import icon2 from "../assets/Icons/icon2.png";
import icon3 from "../assets/Icons/icon3.png";
import aspinLogo from "../assets/Images/aspin-logo-2.png";
import { Button } from "../components/Button";
import CaseStudyCarousel from "../components/CaseStudyCarousel";
import PriceCard from "../components/PriceCard";
import { Link } from "react-router-dom";
import FooterBanner from "../components/FooterBanner";
const tutorialData = [
  {
    img: card1,
    title: "FOR MANAGERS",
    link: "https://player.vimeo.com/video/797650714?h=590b9a79eb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    img: card2,
    title: "FOR COMPANIES",
    link: "https://player.vimeo.com/video/797651962?h=aab0e4d7d9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    img: card3,
    title: "FOR SALE REPRESENTATIVES",
    link: "https://player.vimeo.com/video/794223161?h=7e3f303363&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
];
export default function Home() {
  return (
    <div className="container-fluid px-0">
      <Header />
      <Carousel />
      <SectionHeader
        title="Solutions for all"
        heading="A Cloud-Based CRM & <span class='bg-yellow' > SFE Solution </span>"
        content="MREP is a simple yet highly efficient CRM and SFE solution, it is a one window solution to all of your Sales and Marketing problems."
      />
      <div className="container mb-5 ">
        <div className="row">
          {tutorialData.map((d, i) => {
            return (
              <div className="col-md-4" key={i}>
                <TutorialCard data={d} uniqueId={i} />
              </div>
            );
          })}
        </div>
      </div>
      <div className="py-5">
        <SectionHeader
          className=""
          title="Features"
          heading="Streamline Your Sales Process and <span class='bg-yellow'>Drive Growth</span> with MRep"
          content=" MRep's industry-specific features are designed to help you optimize your sales performance, increase revenue growth, better manage your sales process, and improve forecasting and forecasting accuracy."
        />
      </div>

      <div className="container">
        {/* Section 111111 */}

        <div className="row my-5 ">
          <div className="col-md-5 offset-md-1 col">
            <section className="bg-card- text-sm-center">
              <img
                alt=""
                src={mobile1}
                className="bg-card-1 img-fluid p-5 rounded-29"
              />{" "}
            </section>
          </div>
          <div className="col-md-5  align-self-center ">
            <p className="mrep_dh">Automated Pipeline Management</p>
            <p className="fs-2 mrep_header1">
              Streamline Your Sales Process with Automated Pipeline Management
            </p>
            <p className="text-muted">
              MRep's Automated Pipeline Management module helps you to easily
              manage and track the entire sales process, from lead generation to
              close.
            </p>
          </div>
        </div>

        {/* Section 2222 */}

        <div className="row my-5 ">
          {" "}
          <div className="col-md-5  align-self-center ">
            <p className="mrep_dh">Advanced Reporting and Analytics</p>
            <p className="fs-2 mrep_header1">
              Make Data-Driven Decisions with Advanced Reporting and Analytics
            </p>
            <p className="text-muted">
              MRep's Advanced Reporting and Analytics module provides you with
              the insights you need to make data-driven decisions and improve
              your sales performance.
            </p>
          </div>
          <div className="col-md-5 offset-md-1 col">
            <section className="bg-card- text-sm-center">
              <img
                src={mobile2}
                className="bg-card-2 p-5 img-fluid rounded-29"
              />{" "}
            </section>
          </div>
        </div>
      </div>

      {/* Banner */}

      <div className="container py-5 ">
        <div className="banner-bg rounded-29">
          <div className="row text-white align-self-center h-100 ">
            <div className="col-md-5 col-12 offset-md-1 align-self-center">
              <div className="d-flex align-self-center">
                <img
                  src={featuredIcon}
                  height="40px"
                  className="align-self-center"
                />
                <div className="fs-3 ps-4">
                  Advanced Reporting and Analytics &nbsp;
                  <span className="bg-yellow text-dark">Capabilities</span> for
                  Better Data-driven Decision Making
                </div>
              </div>
            </div>
            <div className="col-md-5 col-12 text-md-end text-center align-self-center">
              <Link className="nav-link text-dark" to="/ContactUs">
                <button className="btn btn-lg text-mrep bg-white">
                  Schedule a Demo
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        {/* Section 111111 */}

        <div className="row my-5 ">
          <div className="col-md-5 offset-md-1 col">
            <section className="bg-card- text-sm-center">
              <img
                alt=""
                src={mobile3}
                className="bg-card-3 img-fluid p-5 rounded-29"
              />{" "}
            </section>
          </div>
          <div className="col-md-5  align-self-center ">
            <p className="mrep_dh">Mobile Access</p>

            <p className="fs-2 mrep_header1">
              Stay Connected and Productive on the Go with MRep Mobile Access
            </p>
            <p className="text-muted">
              MRep's Mobile Access module allows you to access the software from
              your mobile device, so you can stay connected and productive even
              when you're on the go.
            </p>
          </div>
        </div>

        {/* Section 2222 */}

        <div className="row my-5 ">
          {" "}
          <div className="col-md-5  align-self-center ">
            <p className="mrep_dh">Field Expense Management</p>
            <p className="fs-2 mrep_header1">
              Streamline Your Field Expense Management with MRep
            </p>
            <p className="text-muted">
              Our Field Expense Management module allows you to easily track,
              manage, and report on your field reps' expenses, saving time and
              money. Improve your expense management process with MRep.
            </p>
          </div>
          <div className="col-md-5 offset-md-1 col">
            <section className="bg-card- text-sm-center">
              <img
                alt=""
                src={mobile4}
                className="bg-card-4 img-fluid p-5 rounded-29"
              />{" "}
            </section>
          </div>
        </div>
        <div className="collapse" id="collapseExample">
          <div className="row my-5 ">
            <div className="col-md-5 offset-md-1 col">
              <section className="bg-card- text-sm-center">
                <img
                  alt=""
                  src={mobile5}
                  className="bg-card-3 img-fluid p-5 rounded-29"
                />{" "}
              </section>
            </div>
            <div className="col-md-5  align-self-center ">
              <p className="mrep_dh">Coaching Feedback</p>

              <p className="fs-2 mrep_header1">
                Empower Your Sales Reps with MRep
              </p>
              <p className="text-muted">
                Our Sales Rep Coaching module offers a range of tools and
                resources to help you to train and develop your sales reps,
                allowing them to achieve their full potential and increase
                revenue growth. Improve your sales coaching process with MRep.
              </p>
            </div>
          </div>

          <div className="row my-5 ">
            {" "}
            <div className="col-md-5  align-self-center ">
              <p className="mrep_dh">Territory Management/Alignment</p>
              <p className="fs-2 mrep_header1">
                Improve Your Sales Territory Management and Alignment with MRep
              </p>
              <p className="text-muted">
                MRep's Territory Management/Alignment module helps you to better
                manage and align your sales territories, so you can optimize
                your sales performance.
              </p>
            </div>
            <div className="col-md-5 offset-md-1 col">
              <section className="bg-card- text-sm-center">
                <img
                  alt=""
                  src={mobile6}
                  className="bg-card-4 img-fluid p-5 rounded-29"
                />{" "}
              </section>
            </div>
          </div>

          <div className="row my-5 ">
            <div className="col-md-5 offset-md-1 col">
              <section className="bg-card- text-sm-center">
                <img
                  alt=""
                  src={mobile7}
                  className="bg-card-3 img-fluid p-5 rounded-29"
                />{" "}
              </section>
            </div>
            <div className="col-md-5  align-self-center ">
              <p className="mrep_dh">Customer Database Management</p>

              <p className="fs-2 mrep_header1">
                Manage Your Customer Data More Effectively with MRep
              </p>
              <p className="text-muted">
                MRep's Customer Database Management module helps you to
                effectively manage and analyze your customer data, so you can
                better understand your customers and improve your sales
                performance.
              </p>
            </div>
          </div>

          <div className="row my-5 ">
            {" "}
            <div className="col-md-5  align-self-center ">
              <p className="mrep_dh">
                Field Sales Activities Planning and Execution
              </p>
              <p className="fs-2 mrep_header1">
                Improve Your Field Sales Activities Planning and Execution with
                MRep
              </p>
              <p className="text-muted">
                MRep's Field Sales Activities Planning and Execution module
                helps you to plan, execute and track the field sales activities
                effectively.
              </p>
            </div>
            <div className="col-md-5 offset-md-1 col">
              <section className="bg-card- text-sm-center">
                <img
                  alt=""
                  src={mobile8}
                  className="bg-card-4 img-fluid p-5 rounded-29"
                />{" "}
              </section>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center  py-5">
        {/* <Button text="Load More Features" /> */}
        <button
          className="mrep_btn btn bg-mrep text-white collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseExample"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          <span className="if-collapsed">Load More Features</span>
          <span className="if-not-collapsed">Show Less</span>
        </button>
      </div>
      <div className="py-5">
        <SectionHeader
          className=""
          title="case study"
          heading="<span class='mrep_header2'> See How MRep Has Helped Other <span class='bg-yellow' > Pharmaceutical  </span> Companies Succeed </span>"
          content="MRep has helped many companies in the pharmaceutical industry to improve their sales performance, increase revenue growth, and better manage their sales process. Here are a few examples of how MRep has made a difference for our clients."
        />
      </div>

      {/* Case Study Section */}
      <div className="container mb-5">
        <div className="bg-mrep rounded-29">
          <div className="case-study-bg bg-mrep rounded-29">
            <div className="p-md-5 pt-5 pb-md-5 ">
              <CaseStudyCarousel />
            </div>
          </div>
          <div className="px-md-5 mx-4 pt-md-3">
            <hr className="pt-1 rounded bg-light mb-5" />
            {/* Banner */}

            <div className="container py-5 ">
              <div className="mb-5">
                <div className="row text-white align-self-center h-100 ">
                  <div className="col-md-8 px-0 align-self-center">
                    <div className="d-flex align-self-center">
                      <img
                        alt=""
                        src={growIcon}
                        height="40px"
                        className="align-self-center"
                      />
                      <div className="fs-3  mrep_lh ">
                        A Sense of Accomplishment and Success Through
                        Improved&nbsp;
                        <span className="bg-yellow text-dark">
                          Sales Performance
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 px-0 text-md-end text-center align-self-center mt-md-0 mt-3">
                    <Link className="nav-link text-dark" to="/ContactUs">
                      <button className="btn btn-lg text-mrep bg-white">
                        Schedule a Demo
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Case Study Section End*/}

      <div className="my-5">
        <SectionHeader
          className=""
          title="pricing"
          heading="<span class='mrep_header2'> Affordable Pricing That Fits Your <span class='bg-yellow'>Business</span> Needs</span>"
          content="MRep is priced at £5 per user, making it accessible and cost-effective for companies of all sizes.
          "
        />
      </div>

      {/* Pricing Card */}
      <div className="container">
        <div className="row pb-5">
          {[
            {
              icon: icon1,
              title: "basic plan",
              color: "text-color-1",
              price: "£5/month",
              features: ["Access to all basic features"],
            },
            {
              icon: icon2,
              title: "Business plan",
              color: "text-color-2",
              price: "£7/month",
              features: [
                "Access to all basic features",
                "Basic reporting and analytics",
              ],
            },
            {
              icon: icon3,
              title: "Enterprise plan",
              color: "text-color-3",
              price: "Custom Plan",
              features: [
                "Access to all basic features",
                "Basic reporting and analytics",
                "Features according to specific needs",
              ],
            },
          ].map((d) => {
            return (
              <div className="col-md-4 col-8 offset-2 offset-md-0 py-md-0 py-4">
                <PriceCard data={d} />
              </div>
            );
          })}
        </div>
      </div>

      {/* Footer Banner Section */}
      <div className="mb-5 pb-5">
        <FooterBanner />
      </div>
    </div>
  );
}
