import React from "react";
import { Button } from "../Button";
import Nav from "./Nav";
import { Link } from "react-router-dom";

import "../../styles/Home.css";
export default function Header() {
  return (
    <div>
      <div className="header-bg">
        <div className="container">
          <div className="row pt-5">
            <div className="col-lg-5 col-md-6 col-sm-10 mt-5">
              <div className="banner-h mt-5 mrep_header">
                Optimize Your Pharmaceutical Sales Performance with{" "}
                <span className="bg-yellow"> MRep </span>
              </div>
              <p className="fs-4 my-4 ">
                MRep is a powerful CRM and Sales Force Automation software
                specifically designed for the pharmaceutical industry.{" "}
              </p>
              <Link  to="/ContactUs">
                <Button text="Schedule a Demo" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
