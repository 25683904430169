import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/Images/Logo.png";
import { Button } from "../Button";
export default function Nav() {
  return (
    <div className="">
      <nav className="navbar navbar-expand-sm bg-theme px-5 pt-4">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src={logo} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mynavbar"
          >
            <span className="navbar-toggler-icon text-dark menu-icon border rounded border-dark"></span>
          </button>
          <div className="collapse navbar-collapse " id="mynavbar">
            <ul className="navbar-nav  ms-auto">
              <li className="nav-item mx-2">
                <Link className="nav-link text-dark" to="/AboutUs">
                  About Us
                </Link>
                {/* <a className="nav-link text-dark" href="/AboutUs">
                  About Us
                </a> */}
              </li>
              <li className="nav-item mx-2">
                <Link className="nav-link text-dark" to="/FAQs">
                  FAQ
                </Link>
              </li>
              <li className="nav-item me-3 ms-2">
                <Link className="nav-link text-dark" to="/ContactUs">
                  Contact Us
                </Link>
              </li>
              <li className="nav-item">
                <Link className="" to="/ContactUs">
                  <Button text="Schedule a Demo" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
